.SandboxRoot.env-bp-820 .timeline-Tweet-text {
    font-size: 20px !important;
    line-height: 38px !important;
    font-weight: 300;
}

.loginheight {
  height: 50px;
}

.register-box {
    width: 560px;
    margin: 1% auto;
}

.form-control-feedback {
  text-align: left;
}

.btn-wild {
    color: #ffffff;
    background-color: #3ca838;
    border-color: rgba(0, 0, 0, 0.2);
}
.green {
color: #3aaa35
}
.blue {
  color: #7fcff5
}
.darkgreen {
  color: #2f5234
}
.rood {
color: #e50040
}
.roze {
  color: #ea5198
}
.bruin {
  color: #693c10
}
.oranje {
  color: #f7a600
}
.kleur {
  padding-right: 10px;
}

.darkgreenbackground {
  background-color: #2f5234;
  color: #FFFFFF
}
blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}
.tablerow30 {
  width: 30%
}
