body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.content-wrapper {
    flex: 1;
}
.content-page {
  display: flex;
 min-height: 100vh;
 flex-direction: column;
}
.imageholder img {
  width: 100%
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.overlay {
  position: fixed;
  left: 20%;
  top: 30%;
  background:rgba(0,0,0,0.8);
  z-index:100005;
  width:800px;
  height:300px;
}
.overlaytext {
  color: #ffffff;
  padding: 10%;
}